import TableHead from '@/view/pages/posm/campaign/models/TableHead';

export const fields = [
  new TableHead('ID', 'id'),
  new TableHead('Tên Campaign', 'name'),
  new TableHead('TG bắt đầu', 'startDate'),
  new TableHead('TG kết thúc', 'endDate'),
  new TableHead('Số ngày còn lại', 'remainingDays'),
  new TableHead('Chức năng', 'actions'),
];
